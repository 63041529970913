import { getAppList } from '@/plugins/setupMicro'
import { useUserStore } from '@/store/modules/user'
import { computed, defineComponent } from 'vue'
import { useRoute, useRouter } from 'vue-router'

export default defineComponent({
    name: 'LeftSide',
    setup () {
        const route = useRoute()
        const router = useRouter()
        const applicationList = computed(() => {
            const userInfo = useUserStore().getUserInfo
            if (userInfo) {
                const applicationList = userInfo.applicationList.filter(item => item.platform === 'pc')
                applicationList.splice(0, 0, {
                    link: '',
                    icon: '',
                    applicationId: '',
                    appId: '/',
                    applicationName: '首页',
                    scope: '',
                    isSelected: null,
                    iconPath: 'iconcedaohang_shouye',
                    platform: 'pc',
                    routerType: 'router'
                })
                return applicationList
            }
            return []
        })
        const currentApp = computed(() => {
            return route.path === '/' ? '/' : route.path.split('/')[1]
        })
        const handlerToTarget = (url: ApplicationList|{routerType:string;appId:string}) => {
            // 是否微应用打开
            if (url.routerType === 'router') {
                router.push(url.appId === '/' ? '/' : `/${url.appId}`)
            } else {
                const app = getAppList().find((item: any) => item.name === url.appId)
                window.open(app.entry, '_blank')
            }
        }
        return {
            route,
            currentApp,
            applicationList,
            handlerToTarget
        }
    }
})
