
import { defineComponent, onMounted } from 'vue'
import homeCustom from './modular/homeCustom.vue'
import { Column } from '@antv/g2plot'
export default defineComponent({
    name: 'echartCountCis',
    setup () {
        const data = [
            {
                type: '一月',
                sales: 0
            },
            {
                type: '二月',
                sales: 0
            },
            {
                type: '三月',
                sales: 0
            },
            {
                type: '四月',
                sales: 0
            },
            {
                type: '五月',
                sales: 0
            },
            {
                type: '六月',
                sales: 0
            },
            {
                type: '七月',
                sales: 0
            },
            {
                type: '八月',
                sales: 0
            },
            {
                type: '九月',
                sales: 0
            },
            {
                type: '十月',
                sales: 0
            },
            {
                type: '十一月',
                sales: 0
            },
            {
                type: '十二月',
                sales: 0
            }
        ]

        onMounted(() => {
            const columnPlot = new Column('container', {
                data,
                xField: 'type',
                yField: 'sales',
                legend: false,
                height: 180,
                autoFit: true,
                tooltip: {
                    showContent: false
                }
            })

            columnPlot.render()
        })

        return {
            data
        }
    },

    components: {
        homeCustom
    }
})
