
import { defineComponent, ref } from 'vue'
import { formatTime } from 'wd-platform-utils'
import homeCustom from './modular/homeCustom.vue'
export default defineComponent({
    name: 'sysNotice',
    setup () {
        const noticeList = ref<any[]>([
            // {
            //     title: '文都教育集团2021第二季度全体员工交流会',
            //     time: '1623827624'
            // },
            // {
            //     title: '山东文都品牌焕新发布会圆满落幕，三年完成八大区布布布布布布山东文都品牌焕新发布会圆满落幕，三年完成八大区布布布布布布山东文都品牌焕新发布会圆满落幕，三年完成八大区布布布布布布',
            //     time: '1623827624'
            // },
            // {
            //     title: '文都教育集团2021第二季度全体员工交流会',
            //     time: '1623827624'
            // },
            // {
            //     title: '山东文都品牌焕新发布会圆满落幕，三年完成八大区布布布布布布',
            //     time: '1623827624'
            // }
        ])
        return {
            formatTime,
            noticeList
        }
    },
    methods: {

    },
    components: {
        homeCustom
    }
})
