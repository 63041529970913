
import { defineComponent } from 'vue'
export default defineComponent({
    name: 'custom-title',
    props: {
        title: {
            type: String,
            required: true
        },
        isMore: {
            type: Boolean,
            required: false
        }
    },
    setup () {
        return {
        }
    },
    methods: {

    },
    components: {
    }
})
