/* eslint-disable @typescript-eslint/no-unused-vars */
import { getValidateCode, loginToken, sendSmsCode } from '@/service/loginService'
import { currentUser } from '@/service/userService'
import { useUserStore } from '@/store/modules/user'
import { RuleObject } from 'ant-design-vue/lib/form'
import { defineComponent, reactive, ref } from 'vue'
import { useRouter } from 'vue-router'
import { IDialog, sysMsg } from 'wd-platform-library'
import { DialogDataInterface } from 'wd-platform-library/lib/src/IDialog/src/IDialogInterFace'

export default defineComponent({
    name: 'Login',
    setup () {
        const router = useRouter()
        const codeURL = ref < string >('')
        const userInfo = reactive<LoginParams>({
            cms_code: '',
            mobile: '',
            validateCode: ''
        })
        const time = ref(61)
        const validateCodeFormRef = ref()
        const loginFormRef = ref()
        // 传递组件对象
        const dialogData = reactive < DialogDataInterface >({
            width: 500, // 弹框宽度
            show: false, // 弹框是否展示
            title: '验证码验证', // 弹框名字
            onSubmit: async () => {
                await validateCodeFormRef.value.validate().then(async (res:any) => {
                    const resultData = await sendSmsCode({
                        mobile: userInfo.mobile,
                        captcha: userInfo.validateCode || ''
                    })
                    if (resultData) {
                        countdownSendSms()
                        dialogData.onCancel && dialogData.onCancel()
                    }
                }).catch(() => console.log())
            },
            onCancel: () => {
                userInfo.validateCode = ''
                dialogData.show = false
            }
        })

        const btnSubmitLoading = ref(false)
        const loginFormRule = {
            validateCode: [
                {
                    validator: async (_: RuleObject, value: string) => {
                        if (!value) {
                            return Promise.reject('请输入图形验证码')
                        } else {
                            if (value.length !== 5) {
                                return Promise.reject('图形验证码输入有误')
                            } else {
                                return Promise.resolve()
                            }
                        }
                    },
                    trigger: ['blur', 'change']
                }
            ],
            mobile: [
                {
                    validator: async (_: RuleObject, value: string) => {
                        if (!value) {
                            return Promise.reject('请输入手机号')
                        }
                        if (!/^1(2|3|4|5|6|7|8|9)\d{9}$/.test(value)) {
                            return Promise.reject('手机号输入有误')
                        }
                        return Promise.resolve()
                    },
                    trigger: ['blur', 'change']
                }
            ],
            cms_code: [
                { required: true, message: '请输入短信验证码', trigger: ['blur', 'change'] },
                { min: 6, max: 6, message: '短信验证码长度输入有误', trigger: ['blur', 'change'] }
            ]
        }
        const handlerLoginSubmit = async () => {
            await loginFormRef.value.validate().then(async (res:any) => {
                btnSubmitLoading.value = true
                const result = await loginToken({ mobile: userInfo.mobile, cms_code: userInfo.cms_code })
                btnSubmitLoading.value = false
                if (result) {
                    useUserStore().setToken(result.result.accessToken)
                    // 获取用户信息
                    const resultData = await currentUser()
                    if (resultData) {
                        useUserStore().setUserInfo(resultData.result)
                    }
                    router.push('/')
                }
            })
        }
        const getCodeFun = async () => {
            if (await loginFormRef.value.validateFields('mobile')) {
                dialogData.show = true
                userInfo.validateCode = ''
                codeURL.value = getValidateCode({ mobile: userInfo.mobile, time: new Date().getTime() })
            }
        }
        const countdownSendSms = () => {
            time.value = time.value - 1 >= 0 ? time.value - 1 : 0
            if (time.value > 0) {
                setTimeout(() => {
                    countdownSendSms()
                }, 1000)
            }
        }
        return {
            codeURL,
            dialogData,
            validateCodeFormRef,
            getCodeFun,
            time,
            userInfo,
            loginFormRef,
            loginFormRule,
            btnSubmitLoading,
            countdownSendSms,
            handlerLoginSubmit
        }
    },
    components: {
        IDialog
    }
})
