/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { getUserLoginToken, YZJToken } from '@/service/loginService'
import { currentUser } from '@/service/userService'
import { useLoadingStore } from '@/store/modules/loading'
import { useUserStore } from '@/store/modules/user'
import { getRedirectType } from '@/utils/prototypeAPI'
import { setWebViewTitle } from '@/utils/qingApi'
import { isMobile } from '@/utils/request'
import { App, nextTick } from 'vue'
import { createRouter, createWebHistory, RouteLocationNormalized, RouteRecordRaw } from 'vue-router'
const whiteUrlList = ['/login', '/external/msgPrompt', '/redirect']
const excludeUrlList = [
    '/courseProducts/detail/',
    '/examination/commonSense/detail/',
    '/examination/flowPath/detail/',
    '/teacherServer/flowUpDetail/',
    '/teacherServer/modelDetail/',
    '/teachingResearch/qualified/detail/',
    '/teachingResearch/book/detail/',
    '/teachingResearch/achievemen/detail/',
    '/trialClass/detail/'
]
const routes: Array<RouteRecordRaw> = [
    {
        path: '/redirect',
        name: 'Redirect',
        meta: {
            title: '项目跳转'
        },
        component: () => import('@/views/redirect/redirect.vue')
    },
    {
        path: '/',
        name: 'Home',
        meta: {
            title: '文都智能管理平台'
        },
        component: () => isMobile() ? import('@/views/mobile/index.vue') : import('@/views/home/index.vue')
    },
    {
        path: '/external',
        name: '对外',
        component: () => import('@/layout/MainContent.vue'),
        children: [
            {
                path: 'orderSuccess',
                name: '订单提交成功',
                component: () => import('@/views/external/orderSuccess.vue')
            },
            {
                path: 'msgPrompt',
                name: '消息提示',
                component: () => import('@/views/external/msgPrompt.vue')
            }
        ]
    },
    {
        path: '/login',
        name: 'Login',
        meta: {
            title: '登录'
        },
        component: () => isMobile() ? import('@/views/mlogin/index.vue') : import(/* webpackChunkName: "login" */'@/views/login/index.vue')
    },
    {
        path: '/auth:pathMatch(.*)',
        name: 'Auth',
        meta: {
            title: '权限管理',
            code: 'auth'
        },
        component: () => import(/* webpackChunkName: "micro" */ '@/views/micro/index.vue')
    },
    {
        path: '/channel:pathMatch(.*)',
        name: 'Channel',
        meta: {
            title: '文都营销获客',
            code: 'channel'
        },
        component: () => import(/* webpackChunkName: "micro" */ '@/views/micro/index.vue')
    },
    {
        path: '/statistics:pathMatch(.*)',
        name: 'statistics',
        meta: {
            title: '文都数据统计',
            code: 'statistics'
        },
        component: () => import(/* webpackChunkName: "micro" */ '@/views/micro/index.vue')
    },
    {
        path: '/consult:pathMatch(.*)',
        name: 'consult',
        meta: {
            title: '文都咨询工具',
            code: 'consult'
        },
        component: () => import(/* webpackChunkName: "micro" */ '@/views/micro/index.vue')
    },
    {
        path: '/product:pathMatch(.*)',
        name: 'product',
        meta: {
            title: '文都产品管理',
            code: 'product'
        },
        component: () => import(/* webpackChunkName: "micro" */ '@/views/micro/index.vue')
    },
    {
        path: '/morder:pathMatch(.*)',
        name: 'morder',
        meta: {
            title: '选课中心',
            code: 'morder'
        },
        component: () => import(/* webpackChunkName: "micro" */ '@/layout/microM.vue')
    },
    {
        path: '/order:pathMatch(.*)',
        name: 'order',
        meta: {
            title: '订单管理',
            code: 'order'
        },
        component: () => import(/* webpackChunkName: "micro" */ '@/views/micro/index.vue')
    },
    {
        path: '/educational:pathMatch(.*)',
        name: 'educational',
        meta: {
            title: '教务管理',
            code: 'educational'
        },
        component: () => import(/* webpackChunkName: "micro" */ '@/views/micro/index.vue')
    },
    {
        path: '/marketing:pathMatch(.*)',
        name: 'marketing',
        meta: {
            title: '营销系统',
            code: 'marketing'
        },
        component: () => import(/* webpackChunkName: "micro" */ '@/views/micro/index.vue')
    },
    {
        path: '/student:pathMatch(.*)',
        name: 'student',
        meta: {
            title: '线索管理(PC端)',
            code: 'student'
        },
        component: () => import(/* webpackChunkName: "micro" */ '@/views/micro/index.vue')
    },
    {
        path: '/contract:pathMatch(.*)',
        name: 'contract',
        meta: {
            title: '电子合同',
            code: 'contract'
        },
        component: () => import(/* webpackChunkName: "micro" */ '@/views/micro/index.vue')
    },
    {
        path: '/consultsys:pathMatch(.*)',
        name: 'consultsys',
        meta: {
            title: '咨询助手(后台)',
            code: 'consultsys'
        },
        component: () => import(/* webpackChunkName: "micro" */ '@/views/micro/index.vue')
    },
    {
        path: '/consulthelper:pathMatch(.*)',
        name: 'consulthelper',
        meta: {
            title: '咨询助手(pc)',
            code: 'consulthelper'
        },
        component: () => import(/* webpackChunkName: "micro" */ '@/views/micro/index.vue')
    },
    {
        path: '/mstudent:pathMatch(.*)',
        name: 'mstudent',
        meta: {
            title: '线索管理(移动端)',
            code: 'mstudent'
        },
        component: () => import(/* webpackChunkName: "micro" */ '@/layout/microM.vue')
    },
    {
        path: '/mconsulthelper:pathMatch(.*)',
        name: 'mconsulthelper',
        meta: {
            title: '咨询助手(移动端)',
            code: 'mconsulthelper'
        },
        component: () => import(/* webpackChunkName: "micro" */ '@/layout/microM.vue')
    },
    {
        path: '/error/:code',
        name: 'error',
        meta: {
            title: '异常详情'
        },
        component: () => import(/* webpackChunkName: "error" */'@/views/error/index.vue')
    },
    {
        path: '/:pathMatch(.*)',
        name: 'NotFound',
        meta: {
            title: '异常详情'
        },
        redirect: '/error/404'
    }
]

export const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach(async (to: RouteLocationNormalized, _: RouteLocationNormalized, next: any) => {
    const userStore = useUserStore()
    if (excludeUrlList.findIndex(item => to.path.indexOf(item) > -1) > -1) {
        next()
    } else {
        if (to.query.ticket || to.query.code) {
            if (whiteUrlList.includes(to.path)) {
                next()
            } else {
                loginByThird(to.query as ThirdLoginParams)
            }
        } else if (!whiteUrlList.includes(to.path) && (!userStore.getUserToken) && (to.query.ticket || to.query.code)) {
            loginByThird(to.query as ThirdLoginParams)
        } else if (!whiteUrlList.includes(to.path) && (!userStore.getUserToken)) {
            userStore.setToken(null)
            next('/login')
        } else if ((to.path !== '/login') && (userStore.getUserInfo === null) && (userStore.getUserToken !== null)) {
            const userResult = await currentUser()
            if (userResult) {
                userStore.setUserInfo(userResult.result)
                next()
            } else {
                next('/login')
            }
        } else {
            next()
        }
        nextTick(() => {
            useLoadingStore().setLoadingState(true)
        })
    }
})

router.afterEach(() => {
    nextTick(() => {
        useLoadingStore().setLoadingState(false)
    })
})

const loginByThird = async ({ ticket, appid, client_id, code, clientType, otherType }: any): Promise<void> => {
    if (!ticket && !appid && !client_id && !code) {
        router.push('/login')
    } else {
        const resultData = await getUserLoginToken({ ticket: ticket || '', appid: appid || '', code: code || '', clientType: clientType || '' })
        if (resultData) {
            useUserStore().setToken(resultData.result.accessToken)
            useUserStore().setClientType(clientType)
            const userResult = await currentUser()
            if (userResult) {
                useUserStore().setUserInfo(userResult.result)
                router.push('/')
                if (otherType && otherType === 'tjbg') {
                    window.open(getRedirectType.tjbg)
                }
            }
        } else {
            router.push('/login')
        }
    }
}

export const setupRouter = (app: App): void => {
    app.use(router)
}
export default router
