
import { defineComponent } from 'vue'
import homeCustom from './modular/homeCustom.vue'
export default defineComponent({
    name: 'echartCountCis',
    setup () {
        return {
        }
    },
    methods: {

    },
    components: {
        homeCustom
    }
})
