import { defineComponent } from 'vue'
import appModular from './component/appModular.vue'
import echartCountCis from './component/echartCountCis.vue'
import echartCountCrm from './component/echartCountCrm.vue'
import echartCountoOutbround from './component/echartCountoOutbround.vue'
import echartCountOrder from './component/echartCountOrder.vue'
import echartProduct from './component/echartProduct.vue'
import helpDocument from './component/helpDocument.vue'
import orgNotice from './component/orgNotice.vue'
import sysNotice from './component/sysNotice.vue'
import taskTimeModular from './component/taskTimeModular.vue'
import WdHeader from '@/components/WdHeader/WdHeader.vue'
export default defineComponent({
    name: 'Home',
    components: {
        appModular,
        taskTimeModular,
        sysNotice,
        echartCountCis,
        echartCountCrm,
        echartCountoOutbround,
        echartCountOrder,
        orgNotice,
        helpDocument,
        WdHeader,
        echartProduct
    }
})
