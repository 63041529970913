
import { defineComponent, ref } from 'vue'
import homeCustom from './modular/homeCustom.vue'
export default defineComponent({
    name: 'sysNotice',
    setup () {
        const workList = ref<any[]>([
            // '查看学员', '查看资料', '云凤蝶列表体验优化', '消息列表体验优化', '文档中心'
        ])
        return {
            workList
        }
    },
    methods: {

    },
    components: {
        homeCustom
    }
})
