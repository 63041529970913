import { createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-067d0834"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "no-data" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_empty = _resolveComponent("a-empty")!
  const _component_home_custom = _resolveComponent("home-custom")!

  return (_openBlock(), _createBlock(_component_home_custom, {
    title: '机构公告',
    isMore: false
  }, {
    default: _withCtx(() => [
      _createCommentVNode(" <ul>\n                <li class=\"notice-li\" v-for=\"(item,index) in noticeList\" :key=\"index\">\n                    <div class=\"title\">{{item.title}}</div>\n                    <div class=\"time\">{{formatTime(item.time)}}</div>\n                </li>\n            </ul> "),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_a_empty)
      ])
    ]),
    _: 1 /* STABLE */
  }))
}