
import { defineComponent, ref, reactive } from 'vue'
import homeCustom from './modular/homeCustom.vue'
import { formatTime } from 'wd-platform-utils'
import { IDialog, sysMsg } from 'wd-platform-library'
import { DialogDataInterface } from 'wd-platform-library/lib/src/IDialog/src/IDialogInterFace'
export default defineComponent({
    name: 'sysNotice',
    setup () {
        const noticeDetail = ref<any>({})
        const dialogData = reactive < DialogDataInterface >({
            width: 800, // 弹框宽度
            show: false, // 弹框是否展示
            title: '' // 弹框名字
            // onSubmit: async () => {
            // }
        })
        const noticeList = ref < any[] >([
            {
                title: 'CRM 3.0版本上线通知',
                time: '2022-07-07',
                content: `<p>CRM 3.0版本已正式上线，有助于进一步规范线索管理，沉淀集团范围内的线索数据，挖掘数据的价值。由于新旧版本操作上存在较大的差异，同时涉及咨询师下单操作等常用业务环节，为了平滑衔接，经过沟通，上线后设定2周的过渡期（2022年7月7日-2022年7月20日），过渡期内各单位继续使用现行版本完成资源录入和下单。</p>
                    <p>在新旧版本过渡期间，请大家注意以下事项：</p>
                    <p>1、请各使用单位的管理员核对基础信息配置和设置，包括组织、用户、权限、主要参数和标签；</p>
                    <p>2、请使用用户根据前期培训的培训说明尽快熟悉系统基本操作；</p>
                    <p>3、发现任何功能优化问题请及时反馈至品牌市场部马倩老师或各沟通群；</p>
                    <p>4、过渡期结束后迁移全部旧版本数据，停用旧版本，完成切换工作</p>`
            }
        ])
        const detailFun = (data:any) => {
            dialogData.show = true
            noticeDetail.value = data
        }
        return {
            noticeDetail,
            noticeList,
            dialogData,
            detailFun,
            formatTime
        }
    },
    components: {
        homeCustom,
        IDialog
    }
})
