
import { defineComponent, onMounted } from 'vue'
import homeCustom from './modular/homeCustom.vue'
import { Pie } from '@antv/g2plot'
export default defineComponent({
    name: 'echartProduct',
    setup () {
        const data = [
            { type: '集训营', value: 0 },
            { type: '彩虹卡', value: 0 },
            { type: '慧学', value: 0 },
            { type: '网课', value: 0 },
            { type: '一对一', value: 0 },
            { type: '其他', value: 0 }
        ]
        onMounted(() => {
            const piePlot = new Pie('product_container', {
                data,
                angleField: 'value',
                colorField: 'type',
                // autoFit: true,
                // width: 250,
                height: 180,
                label: false
            })

            piePlot.render()
        })
        return {
            data
        }
    },
    methods: {

    },
    components: {
        homeCustom
    }
})
