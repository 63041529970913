
import { defineComponent, ref } from 'vue'
import homeCustom from './modular/homeCustom.vue'
export default defineComponent({
    name: 'taskTimeModular',
    setup () {
        const taskList = ref<Array<any>>([
            {
                title: '总任务数',
                value: '200',
                scale: '100%'
            },
            {
                title: '运行中的任务',
                value: '80',
                scale: '0.4%'
            },
            {
                title: '任务报错',
                value: '10',
                scale: '0.1%'
            },
            {
                title: '任务警告',
                value: '10',
                scale: '0.1%'
            }
        ])

        return {
            taskList
        }
    },
    methods: {

    },
    components: {
        homeCustom
    }
})
