import { defineStore } from 'pinia'

export const useUserStore = defineStore('user', {
    state: () => ({
        token: null as (string | null),
        userInfo: null as (User | null),
        clientType: 'yzj'
    }),
    getters: {
        getUserToken: state => {
            return state.token || localStorage.getItem('wd_fant_auth')
        },
        getUserInfo: state => {
            return state.userInfo
        },
        getClientType: () => {
            return localStorage.getItem('clientType')
        }
    },
    actions: {
        setClientType (clientType = 'yzj'): void {
            localStorage.setItem('clientType', clientType)
            this.clientType = clientType
        },
        setToken (token: string | null): void {
            token ? localStorage.setItem('wd_fant_auth', token) : localStorage.removeItem('wd_fant_auth')
            this.token = token
        },
        setUserInfo (user: User | null): void {
            this.userInfo = user
        }
    }

})
