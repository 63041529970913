
import { defineComponent, onMounted } from 'vue'
import homeCustom from './modular/homeCustom.vue'
import { Line } from '@antv/g2plot'
export default defineComponent({
    name: 'echartCountCis',
    setup () {
        const data = [
            {
                Date: '2022-01',
                scales: 0
            },
            {
                Date: '2022-02',
                scales: 0
            },
            {
                Date: '2022-03',
                scales: 0
            },
            {
                Date: '2022-04',
                scales: 0
            },
            {
                Date: '2022-05',
                scales: 0
            },
            {
                Date: '2022-06',
                scales: 0
            },
            {
                Date: '2022-07',
                scales: 0
            },
            {
                Date: '2022-08',
                scales: 0
            },
            {
                Date: '2022-09',
                scales: 0
            },
            {
                Date: '2022-10',
                scales: 0
            },
            {
                Date: '2022-11',
                scales: 0
            },
            {
                Date: '2022-12',
                scales: 0
            }
        ]

        onMounted(() => {
            const bar = new Line('crm_container', {
                data,
                xField: 'Date',
                yField: 'scales',
                height: 180,
                annotations: [
                    // {
                    //     type: 'text',
                    //     position: ['min', 'median'],
                    //     content: '中位数',
                    //     offsetY: -4,
                    //     style: {
                    //         textBaseline: 'bottom'
                    //     }
                    // },
                    // {
                    //     type: 'line',
                    //     start: ['min', 'median'],
                    //     end: ['max', 'median'],
                    //     style: {
                    //         stroke: 'red',
                    //         lineDash: [2, 2]
                    //     }
                    // }
                ]

            })

            bar.render()
        })
        return {
        }
    },
    methods: {

    },
    components: {
        homeCustom
    }
})
