
import { defineComponent, onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import { getUrlParam } from 'wd-platform-utils'
import * as dd from 'dingtalk-jsapi'
import { getCORPID, getRedirectType, getDingDingAPPID } from '@/utils/prototypeAPI'
import { isMobile } from '@/utils/request'
export default defineComponent({
    name: 'PaySuccess',
    setup () {
        const router = useRouter()
        const appCode = ref<string|null>(null)
        const redirectUrl = ref<string>('')
        const platformVail = ref<string>(isMobile() ? 'mobile' : 'pc')
        onMounted(() => {
            appCode.value = getUrlParam('appCode') || null
            getDingdingCode()
        })
        const getDingdingCode = () => {
            dd.ready(() => {
                dd.runtime.permission.requestAuthCode({
                    corpId: getCORPID(),
                    onSuccess: (result:any) => {
                        redirectProject(result.code)
                    },
                    onFail: () => {
                        router.push('/login')
                    }
                })
            })
        }
        const onClick = () => {
            getDingdingCode()
        }
        const redirectProject = (userCode:string) => {
            if (appCode.value) {
                if (platformVail.value === 'mobile') {
                    router.push('/?code=' + userCode + '&clientType=dingding&appid=' + getDingDingAPPID())
                } else {
                    let param = ''
                    if (appCode.value === 'tjbg') {
                        param = '&otherType=' + 'tjbg'
                        redirectUrl.value = 'dingtalk://dingtalkclient/page/link?url=' + encodeURIComponent(getRedirectType.znpt + '?code=' + userCode + '&clientType=dingding&appid=' + getDingDingAPPID() + param) + '&pc_slide=false'
                    } else {
                        redirectUrl.value = 'dingtalk://dingtalkclient/page/link?url=' + encodeURIComponent(getRedirectType[appCode.value] + '?code=' + userCode + '&clientType=dingding&appid=' + getDingDingAPPID() + param) + '&pc_slide=false'
                    }
                    window.location.href = redirectUrl.value
                }
            } else {
                router.push('/login')
            }
        }
        return {
            platformVail,
            getDingdingCode,
            onClick,
            redirectUrl,
            redirectProject,
            appCode
        }
    }
})

