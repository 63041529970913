
import { defineComponent, onMounted } from 'vue'
import homeCustom from './modular/homeCustom.vue'
import { Column } from '@antv/g2plot'
export default defineComponent({
    name: 'echartCountCis',
    setup () {
        const data = [
            {
                type: '法考',
                sales: 0
            },
            {
                type: '公考',
                sales: 0
            },
            {
                type: '四六级',
                sales: 0
            },
            {
                type: '考研',
                sales: 0
            },
            {
                type: '医学',
                sales: 0
            },
            {
                type: '其他',
                sales: 0
            }
        ]

        onMounted(() => {
            const columnPlot = new Column('order_container', {
                data,
                xField: 'type',
                yField: 'sales',
                height: 180
            })

            columnPlot.render()
        })
        return {
            data
        }
    },
    methods: {

    },
    components: {
        homeCustom
    }
})
