import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5954443c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "app-module" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_wd_header = _resolveComponent("wd-header")!
  const _component_a_layout_header = _resolveComponent("a-layout-header")!
  const _component_app_modular = _resolveComponent("app-modular")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_echart_count_cis = _resolveComponent("echart-count-cis")!
  const _component_echart_count_crm = _resolveComponent("echart-count-crm")!
  const _component_sys_notice = _resolveComponent("sys-notice")!
  const _component_echart_count_order = _resolveComponent("echart-count-order")!
  const _component_echart_product = _resolveComponent("echart-product")!
  const _component_help_document = _resolveComponent("help-document")!
  const _component_a_layout_content = _resolveComponent("a-layout-content")!
  const _component_a_layout = _resolveComponent("a-layout")!

  return (_openBlock(), _createBlock(_component_a_layout, { class: "fant-micro-main" }, {
    default: _withCtx(() => [
      _createVNode(_component_a_layout_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_wd_header)
        ]),
        _: 1 /* STABLE */
      }),
      _createVNode(_component_a_layout_content, { class: "modular-data" }, {
        default: _withCtx(() => [
          _createVNode(_component_a_row, { gutter: [16,16] }, {
            default: _withCtx(() => [
              _createVNode(_component_a_col, {
                span: 24,
                class: "home-modular-new"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_1, [
                    _createVNode(_component_app_modular)
                  ]),
                  _createCommentVNode(" <div class=\"task-module\">\n                        <task-time-modular></task-time-modular>\n                    </div> ")
                ]),
                _: 1 /* STABLE */
              }),
              _createCommentVNode(" <a-col :span=\"8\" class=\"home-modular-new\">\n                    <div><sys-notice></sys-notice></div>\n\n                </a-col> ")
            ]),
            _: 1 /* STABLE */
          }),
          _createCommentVNode(" <div style=\"height:16px;\"></div> "),
          _createVNode(_component_a_row, { gutter: [16,16] }, {
            default: _withCtx(() => [
              _createVNode(_component_a_col, {
                span: 8,
                class: "home-modular"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_echart_count_cis)
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_a_col, {
                span: 8,
                class: "home-modular"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_echart_count_crm)
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_a_col, {
                span: 8,
                class: "home-modular"
              }, {
                default: _withCtx(() => [
                  _createCommentVNode(" <org-notice></org-notice> "),
                  _createVNode(_component_sys_notice)
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_a_col, {
                span: 8,
                class: "home-modular"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_echart_count_order)
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_a_col, {
                span: 8,
                class: "home-modular"
              }, {
                default: _withCtx(() => [
                  _createCommentVNode(" <echart-counto-outbround></echart-counto-outbround> "),
                  _createVNode(_component_echart_product)
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_a_col, {
                span: 8,
                class: "home-modular"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_help_document)
                ]),
                _: 1 /* STABLE */
              })
            ]),
            _: 1 /* STABLE */
          })
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _: 1 /* STABLE */
  }))
}