import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-07e274ad"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "home-custom" }
const _hoisted_2 = { class: "custom-title" }
const _hoisted_3 = { class: "title" }
const _hoisted_4 = {
  key: 0,
  class: "more"
}
const _hoisted_5 = { class: "custom-main" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.title), 1 /* TEXT */),
      (_ctx.isMore)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, "更多"))
        : _createCommentVNode("v-if", true)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ])
  ]))
}