export const getCORPID = ():string => {
    return (process.env.VUE_APP_CORPID as string).toString()
}
export const getDingDingAPPID = ():string => {
    return (process.env.VUE_APP_DINGDINGAPPID as string).toString()
}
export const getOrderAppId = ():string => {
    return (process.env.VUE_APP_ORDER_APPID as string).toString()
}
export const getOrderURL = ():string => {
    return (process.env.VUE_APP_ORDER_URL as string).toString()
}
export const getAppId = ():string => {
    return (process.env.VUE_APP_APPID as string).toString()
}
export const getAppUrl = ():string => {
    return (process.env.VUE_APP_APPURL as string).toString()
}
export const getRedirectType:{[key:string]:string } = {
    znpt: (process.env.VUE_APP_ZNPT as string).toString(),
    cis: (process.env.VUE_APP_CIS as string).toString(),
    permission: (process.env.VUE_APP_PERMISSION as string).toString(),
    educational: (process.env.VUE_APP_EDUCATIONAL as string).toString(),
    teacher: (process.env.VUE_APP_TEACHER as string).toString(),
    tjbg: (process.env.VUE_APP_TJBG as string).toString()
}
