import 'viewerjs/dist/viewer.css'
// import '../node_modules/wd-platform-library/lib/style.min.css'
import './assets/css/scss/index.scss'
import { setupAntd, setupVxeTable } from '@/plugins'
import { setupStore } from '@/store'
import { createApp } from 'vue'
import App from './App.vue'
import router, { setupRouter } from './router'
import './utils/flexiable'
const app = createApp(App)
setupAntd(app)
setupVxeTable(app)
setupStore(app)
setupRouter(app)
app.mount('#app')
