
import { defineComponent, ref } from 'vue'
import { getCORPID, getRedirectType, getAppId, getAppUrl, getDingDingAPPID } from '@/utils/prototypeAPI'
import { BellOutlined, ArrowRightOutlined } from '@ant-design/icons-vue'
import { isMobile } from '@/utils/request'
import { getUrlParam } from 'wd-platform-utils'
import * as dd from 'dingtalk-jsapi'
import { useRouter } from 'vue-router'
export default defineComponent({
    name: 'msgPrompt',
    components: { BellOutlined, ArrowRightOutlined },
    setup () {
        const router = useRouter()
        const isMoble = ref(isMobile())
        const openFun = () => {
            try {
                dd.ready(() => {
                    dd.runtime.permission.requestAuthCode({
                        corpId: getCORPID(),
                        onSuccess: (result:any) => {
                            dingdingRedirectFun(result.code)
                        },
                        onFail: () => {
                            YZJRedirectFun()
                        }
                    })
                })
            } catch (error) {
                YZJRedirectFun()
            }
        }
        const dingdingRedirectFun = (userCode:string) => {
            if (isMoble.value) {
                router.push('/?code=' + userCode + '&clientType=dingding&appid=' + getDingDingAPPID())
            } else {
                const url = 'dingtalk://dingtalkclient/page/link?url=' + encodeURIComponent(getRedirectType.znpt + '?code=' + userCode + '&clientType=dingding&appid=' + getDingDingAPPID()) + '&pc_slide=false'
                window.location.href = url
            }
        }
        const YZJRedirectFun = () => {
            if (isMoble.value) {
                window.qing.call('gotoLightApp', { appId: '500798782', appName: 'CRM3.0' })
            } else {
                var ticket = getUrlParam('ticket')
                var clientId = getUrlParam('client_id')
                window.open(getAppUrl() + '?ticket=' + ticket + '&client_id=' + clientId + '&appid=' + getAppId())
            }
        }
        return {
            dingdingRedirectFun,
            YZJRedirectFun,
            isMoble,
            openFun
        }
    }
})

