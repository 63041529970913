/* eslint-disable @typescript-eslint/no-unused-vars */
import { router } from '@/router'
import { useLoadingStore } from '@/store/modules/loading'
import { useMenuStore } from '@/store/modules/menu'
import { useUserStore } from '@/store/modules/user'
import { addErrorHandler, initGlobalState, registerMicroApps, start } from 'qiankun'
import devJson from '../../public/config/dev.json'
import textJson from '../../public/config/text.json'
import developmentJson from '../../public/config/development.json'
import fatJson from '../../public/config/fat.json'
import productionJson from '../../public/config/production.json'
// 环境配置
const appMap = new Map([
    ['production', productionJson],
    ['fat', fatJson],
    ['develop', devJson],
    ['text', textJson],
    ['default', developmentJson]
])
const envName = process.env.NODE_ENV
const state = { projectList: null }
const genActiveRule = (routerPrefix: string) => {
    return (location: { pathname: string }) => location.pathname.startsWith(routerPrefix + '/')
}
// 获取子项目配置列表
export const getAppList = (): any[] => {
    const resultArr = appMap.get(envName as string) || appMap.get('default')
    return resultArr.map((item: any) => {
        return {
            name: item.name, // app name registered
            title: item.title,
            entry: item.entry, // 子项目端口号----微应用的入口
            container: '#micro_mian_child', // 微应用的容器节点的选择器或者 Element 实例
            activeRule: genActiveRule(item.activeRule),
            props: {
                store: {
                    getters: {
                        getUserData: useUserStore().getUserInfo
                    }
                }
            }
        }
    })
}
// 对外暴露应用
export const setupMicro = (): void => {
    registerMicroApps(getAppList(), {
        beforeLoad: [
            (_: any): any => {
                useLoadingStore().setLoadingState(true)
            }
        ],
        beforeMount: [
            (_: any): any => {
                useLoadingStore().setLoadingState(true)
            }
        ],
        afterMount: [
            (_: any): any => {
                useLoadingStore().setLoadingState(false)
            }
        ]
    })

    // 捕获加载异常
    addErrorHandler((error: any) => {
        console.log(error)
        alert(error + '123')
        router.push('/error/500')
    })

    // 接收子应用上传的菜单信息
    const actions = initGlobalState(state)
    actions.onGlobalStateChange((state) => {
        useMenuStore().setMenuList(state.projectList as MenuList)
    })
    start({
        prefetch: false,
        sandbox: {
            strictStyleIsolation: false,
            experimentalStyleIsolation: true
        }
    })
}
